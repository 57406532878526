export const SENSEI_BACKEND_ENVS = ["dev", "demo", "storetest", "prod"]

export function BuildSenseiAuthURL(env) {
    if(!SENSEI_BACKEND_ENVS.includes(env)) {
        throw new Error("Invalid environment")
    }
    if (env === "prod") {
        return `https://auth.sensei.tech`
    }
    return `https://auth.${env}.sensei.tech`
}

export function BuildSenseiBackendAPIURL(env) {
    if(!SENSEI_BACKEND_ENVS.includes(env)) {
        throw new Error("Invalid environment")
    } 
    if (env === "prod") {
        return `https://api.sensei.tech`
    }
    return `https://api.${env}.sensei.tech`
}

