import React, { useContext, useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "ka-table/style.css";
import { UserContext } from "../context/UserContext";
import { Stack } from "react-bootstrap"
import { kaReducer, Table } from 'ka-table';
import { useParams } from "react-router-dom";
import { ActionType, DataType, SortingMode } from 'ka-table/enums';
import { loadData, updateData } from "ka-table/actionCreators";
import { Chart } from "react-google-charts"

const tablePropsInit = {
    columns: [
        { key: 'creation_date', title: 'Date', dataType: DataType.String },
        { key: 'username', title: 'User', dataType: DataType.String },
        { key: 'entity_type', title: 'Entity', dataType: DataType.String },
        { key: 'entity_id', title: 'Deployment Id', dataType: DataType.String },
        { key: 'field_name', title: 'Property', dataType: DataType.String },
        { key: 'new_value', title: 'New Value', dataType: DataType.String },
    ],
    data: [],
    rowKeyField: 'change_id',
    sortingMode: SortingMode.Single,
    singleAction: loadData(),
    paging: {
        enabled: true,
        pageIndex: 0,
        pageSize: 15
    }
};

export default function Changes(props) {
    const [tableProps, changeTableProps] = useState(tablePropsInit)
    const { token, userenv, setToken } = useContext(UserContext)
    const [progressReport, setProgressReport] = useState(null)

    const { storeId } = useParams()

    useEffect(() => {
        props.setSelectedTab("changes")
    })
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/progress-report`,
            {
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                })
            })
            .then(response => {
                if(response.status === 401){
                    setToken(null); 
                }else return response.json();
            })
            .then(data => setProgressReport(data))
            .catch(err => console.log(err))
    }, [storeId, token, setToken, userenv])
    
    const dispatch = useCallback(async (action) => {
        changeTableProps((prevState) => kaReducer(prevState, action));
        if (action.type === ActionType.LoadData) {
            const response = await fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/changes`,
                {
                    headers: new Headers({
                        "Authorization": "Bearer " + token,
                    })
                })
            if(response.status === 401){
                setToken(null); 
            }
            const data = await response.json()
            dispatch(updateData(data))
        }
    }, [storeId, token, userenv, setToken]);
    
    useEffect(() => {
        dispatch(loadData())
    }, [storeId, dispatch])

 

    const buildChartOptions = (chartTitle) => {
        return {
            title: chartTitle,
            fontSize: 16,
            legend: 'none',
            pieHole: 0.6,
            titleTextStyle: {
                color: "white"
            },
            pieStartAngle: 0,
            pieSliceText: 'none',
          
            slices: {
                0: {color: "green"},
                1: {color: "white"}
            },
            backgroundColor: { fill:'transparent' }
        }
    }

    return (
        <div className="App">
            <header className="App-header">
                <h2 className="mt-5 mb-5"><br/>Deployment Progress</h2>
                {progressReport &&
                    <Stack direction="horizontal" className="Progress-stack" gap={5}>
                        <div>
                            <Chart
                                chartType="PieChart"
                                data={[["Connected", "Amount"], 
                                       ["Connected", progressReport.cameras_connected],
                                       ["Not Connected",  progressReport.total_cameras - progressReport.cameras_connected],
                                    ]}
                                width="240px"
                                height="200px"
                                options={buildChartOptions("Cameras Connected")}
                                />
                        </div>
                        <div>
                            <Chart
                                chartType="PieChart"
                                data={[["Type", "Amount"], 
                                       ["QA Checked", progressReport.cameras_qa_checked],
                                       ["Not Checked", progressReport.total_cameras - progressReport.cameras_qa_checked],
                                    ]}
                                width="240px"
                                height="200px"
                                options={buildChartOptions("Cameras QA Checked")}
                                />
                        </div>
                        <div>
                            <Chart
                                chartType="PieChart"
                                data={[["Type", "Amount"], 
                                       ["Sampled", progressReport.cubaruco_placements_sampled],
                                       ["Not Sampled", progressReport.total_cubaruco_placements - progressReport.cubaruco_placements_sampled],
                                    ]}
                                width="240px"
                                height="200px"
                                options={buildChartOptions("CubArucos Sampled")}
                                />
                        </div>
                        <div>
                            <Chart
                                chartType="PieChart"
                                data={[["Type", "Amount"], 
                                       ["Calibrated", progressReport.gondolas_calibrated],
                                       ["Not Calibrated", progressReport.total_gondolas - progressReport.gondolas_calibrated],
                                    ]}
                                width="240px"
                                height="200px"
                                options={buildChartOptions("Gondolas Calibrated")}
                                />
                        </div>
                        <div>
                            <Chart
                                chartType="PieChart"
                                data={[["Type", "Amount"], 
                                       ["Connected", progressReport.scale_gateways_connected],
                                       ["Not Connected", progressReport.total_scale_gateways - progressReport.scale_gateways_connected],
                                    ]}
                                width="240px"
                                height="200px"
                                options={buildChartOptions("Gateways Connected")}
                                />
                        </div>
                        <div>
                            <Chart
                                chartType="PieChart"
                                data={[["Type", "Amount"], 
                                       ["Checked", progressReport.scales_qa_checked],
                                       ["Not Checked", progressReport.total_scales - progressReport.scales_qa_checked],
                                    ]}
                                width="240px"
                                height="200px"
                                options={buildChartOptions("Scales QA Checked")}
                                />
                        </div>
                    </Stack>
                }

            </header>
            <header className="App-header">
                <h2 className="mb-5"><br />Changes</h2>
            </header>
            <div className="App-body">
                <div className="Changes-Table">
                    <Table className="Changes-Table" {...tableProps} dispatch={dispatch} />
                </div>
            </div>
        </div>
    )
}