import { fabric } from 'fabric'

export const STROKE_FILTERED_COLOR = "#ff6b09"
export const STROKE_UNFILTERED_COLOR = "#0c5daf"
export const FILL_FILTERED_COLOR = "#ffe8b7"
export const CUBARUCO_TRACKING_STROKE_COLOR = "darkred"
export const FILL_UNFILTERED_COLOR = "black"
export const CAMERA_CIRCLE_TEXT_FILL_COLOR_FILTERED = "black"
export const CAMERA_CIRCLE_TEXT_FILL_COLOR_UNFILTERED = "white"

// export const FILL_UNFILTERED_COLOR = "#333333"
export const FILL_COMPLETED_COLOR = "green"

export const DEFAULT_CANVAS_RESOLUTION_X = 1920
export const DEFAULT_CANVAS_RESOLUTION_Y = 1080

const STORE_IMAGE_BACKGROUND_CONTRAST = -0.3

export function InitCanvas(resX, resY, enableSelection = false){
    return new fabric.Canvas('canvas', {
        height: resY,
        width: resX,
        renderOnAddRemove: true,
        selection: enableSelection, //disables multi-selection
    })
}

export function FetchAndCreateBackgroundStoreImage(canvas, storeId, imgType, authToken, userenv) {
    var backgroundImage = new Image()
    fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/image?img_type=${imgType}`,
        {
            headers: new Headers({
                "Authorization": "Bearer " + authToken,
            })
        })
        .then(response => response.blob())
        .then(imageBlob => backgroundImage.src = URL.createObjectURL(imageBlob))

    backgroundImage.onload = () => {
        fabric.Image.fromURL(backgroundImage.src, (img) => {
            img.scaleToWidth(canvas.getWidth())
            img.scaleToHeight(canvas.getHeight())
            const filter = new fabric.Image.filters.Grayscale()
            img.filters.push(filter)
            const contrast = new fabric.Image.filters.Contrast({ contrast: STORE_IMAGE_BACKGROUND_CONTRAST});
            img.filters.push(contrast)
            img.applyFilters()
            canvas.setBackgroundImage(img)
            canvas.renderAll()
        })
    }
}