import React, { useContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Alert, Card, ListGroup, ListGroupItem} from "react-bootstrap"
import { UserContext } from "../context/UserContext"

export default function NetworkSwitch(props) {
    const [switchInfo, setSwitchInfo] = useState(null)
    const {token, userenv, setToken} = useContext(UserContext)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}` +
            `/switches/${props.networkSwitchId}`, 
            {headers:  new Headers({
                "Authorization" : "Bearer " + token
            })})
            .then(response => { 
                if(response.status === 401){
                    setToken(null); 
                }else return response.json();})
            .then(network_switch => {
                setSwitchInfo(network_switch)
             })
    }, [props.storeId, props.networkSwitchId, token, setToken, userenv])

    return (
        <div>
            {switchInfo &&
                <Card className="App-labels mb-5 ">
                    <Card.Body className="Card-body">
                        <Card.Title className="mb-3">Switch {switchInfo.network_switch_id}</Card.Title>
                        Properties
                        <ListGroup className="mt-3 mb-3">
                            <ListGroupItem> Number of ports: {switchInfo.num_ports} </ListGroupItem>
                            <ListGroupItem> Number of cameras:  {switchInfo.camera_ids.length}</ListGroupItem>
                        </ListGroup>                        
                    </Card.Body>
                </Card>
            }
            {!switchInfo &&
                <Card className="App-labels mb-5">
                    <Card.Body className="Card-body">
                        <Card.Title className="mb-4">Switch Details</Card.Title>
                        <Alert variant="warning">
                            Fetching switch details...
                        </Alert>
                    </Card.Body>
                </Card>
            }
        </div>
    )
}