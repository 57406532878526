import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import Cameras from './sections/Cameras';
import Login from "./sections/Login"
import ChangePassword from "./sections/ChangePassword"
import CubArucos from './sections/CuboArucos';
import Gondolas from './sections/Gondolas';
import NotFound from './sections/NotFound';
import { UserContext } from './context/UserContext';
import { useContext, useEffect, useState } from 'react';
import Changes from './sections/Changes';

const DEFAULT_STORE_ID = 1 //NOSCorner

export default function App() {
  const [deployments, setStoreDeployments] = useState(null)
  const [selectedStoreName, setSelectedStoreName] = useState("NOSCorner")
  const [selectedImageType, setSelectedImageType] = useState("Blueprint")
  const [selectedTab, setSelectedTab] = useState("cameras")
  const [isNavBarVisible, setIsNavBarVisible] = useState(true)
  const {token, username, userenv, setToken, setRefreshToken} = useContext(UserContext)
  
  const image_types = ["Blueprint", "Full", "Walls"]
  const navigate = useNavigate();
  const location = useLocation();
  const currentStoreId = parseInt(location.pathname.split("/")[1])

  const handleLogout = () =>{
    localStorage.removeItem("sensei-user-token") 
    localStorage.removeItem("sensei-refresh-token")
    localStorage.removeItem("sensei-user-name")
    localStorage.removeItem("sensei-user-id")
    localStorage.removeItem("sensei-user-env")
    setToken(null)
    setRefreshToken(null)
  }

  useEffect(() => {
    let isResponseOK = false
    fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}`,
    {
        headers: new Headers({
            "Authorization": "Bearer " + token
        })
    }).then(response => {
          if(response.ok){
            isResponseOK = true;
          }
          return response.json()})
      .then(data => {
        if(isResponseOK){
          setStoreDeployments(data);
          setSelectedStoreName(data.filter(d => d.deployment_id === currentStoreId)[0].store_name)
        }
      })
      .catch(error => console.log(error))
    }, [token, currentStoreId, userenv, location]);

  const handleStoreSelected = (id) => {
    let id_int_value = parseInt(id)
    let store_name = deployments.filter(d => d.deployment_id === id_int_value)[0].store_name
    setSelectedStoreName(store_name)
    navigate(`/${id}/${selectedTab}`, {replace: true})
  }

  const handleImageTypeSelected = (img_type) => {
    setSelectedImageType(img_type)
  }


  return (
    <div className="App">
      {isNavBarVisible &&
      <Navbar expand="lg" bg="dark" variant="dark" fixed="top">
        <Container>         
          <Navbar.Brand>
            <img
              src="/sensei-ico.png"
              width="30"
              height="30"
              className="d-inline-block align-top mr-3"
              alt="Sensei logo"
            />
            &ensp; Store Deployments
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          {token && 
          <Nav>
            <Nav.Link href={'/'+currentStoreId+'/cameras'}>Cameras</Nav.Link>
          </Nav>}
          {token && 
          <Nav>
            <Nav.Link href={'/'+currentStoreId+'/cubarucos'}>Calibration</Nav.Link>
          </Nav>}
          {token && 
          <Nav>
            <Nav.Link href={'/'+currentStoreId+'/gondolas'}>Gondolas</Nav.Link>
          </Nav>}
          {token && 
          <Nav className="me-auto">
            <Nav.Link href={'/'+currentStoreId+'/changes'}>Change List</Nav.Link>
          </Nav>}
          {token &&
          <Nav>
            <Navbar.Text >Store:</Navbar.Text>
            {deployments &&
            <NavDropdown onSelect={handleStoreSelected} title={selectedStoreName} menuVariant="dark">
              {deployments.map(d=> 
                <NavDropdown.Item eventKey={d.deployment_id} key={d.deployment_id}>{d.store_name}</NavDropdown.Item>)
              }
            </NavDropdown>}
            <Navbar.Text>&emsp;&emsp;Image:</Navbar.Text>
            <NavDropdown onSelect={handleImageTypeSelected} title={selectedImageType} menuVariant="dark" >
              {image_types.map(t => 
                 <NavDropdown.Item eventKey={t}  key={t}>{t}</NavDropdown.Item>)
              }
            </NavDropdown>
            <Navbar.Text>&emsp;&emsp;User:</Navbar.Text>
            <NavDropdown title={username} menuVariant="dark">
              <NavDropdown.Item href="/change-password">Change Password</NavDropdown.Item>
              <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
            </NavDropdown>
          </Nav>}
          </Navbar.Collapse>
        </Container>
      </Navbar>}
      {!token &&
        <Login />
      }
      {token && deployments &&
        <Routes>
          <Route path="/:storeId/cubarucos" element={<CubArucos setSelectedTab={setSelectedTab}  imgType={selectedImageType.toLowerCase()} />} />
          <Route path="/:storeId/cameras" element={<Cameras setSelectedTab={setSelectedTab} imgType={selectedImageType.toLowerCase()} />} />
          <Route path="/:storeId/gondolas" element={<Gondolas setSelectedTab={setSelectedTab} imgType={selectedImageType.toLowerCase()}/>} /> 
          <Route path="/:storeId/changes" element={<Changes setSelectedTab={setSelectedTab} imgType={selectedImageType.toLowerCase()}/>} /> 
          <Route path="/change-password" element={<ChangePassword username={username}/>} />
          <Route path="/" element={<Navigate replace to={'/'+DEFAULT_STORE_ID+'/'+selectedTab}/>} />
          <Route path="/404" element={<NotFound setIsNavBarVisible={setIsNavBarVisible}/>} />
          <Route path="*" element={<Navigate to="/404"/>}/>
        </Routes>
      }
    </div>
  );
}
