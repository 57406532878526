import React, { useCallback, useContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { fabric } from 'fabric'
import { useEffect, useRef, useState } from "react";
import { Card, Alert, Row, Col, Form } from "react-bootstrap"
import { UserContext } from "../context/UserContext";
import CubAruco from "../components/CubAruco";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { FetchAndCreateBackgroundStoreImage, InitCanvas, FILL_COMPLETED_COLOR, CUBARUCO_TRACKING_STROKE_COLOR, FILL_UNFILTERED_COLOR, STROKE_UNFILTERED_COLOR, DEFAULT_CANVAS_RESOLUTION_X, DEFAULT_CANVAS_RESOLUTION_Y} from "../helpers/FabricJSHelpers";

export default function CubArucos(props) {
    const canvas = useRef(null)
    const [selectedCubAruco, setSelectedCubAruco] = useState(null)
    const { token, userenv, setToken } = useContext(UserContext)
    const { storeId } = useParams()
    const [numOfPlacements, setNumOfPlacements] = useState(0)
    const [numOfSamples, setNumOfSamples] = useState(0)
    const location = useLocation()

    const navigate = useNavigate()
    
    const determineCubArucoFillColor = (cubaruco) => {
        if ((cubaruco.sampled && cubaruco.location_type === "tracking") ||
            (cubaruco.sampled && cubaruco.double_sampled && cubaruco.location_type === "shelf")) {
            return FILL_COMPLETED_COLOR
        }
        return FILL_UNFILTERED_COLOR
    }

    const addCubArucoSquare = useCallback((cubaruco) => {
        const size = 20
        let c = new fabric.Rect({
            cubaruco_id: cubaruco.cubaruco_id,
            sampled: cubaruco.sampled,
            double_sampled: cubaruco.double_sampled,
            location_type: cubaruco.location_type,
            camera_ids: cubaruco.camera_ids,
            left: Math.round(cubaruco.pos_x) - (size / 2),
            top: Math.round(cubaruco.pos_y) - (size / 2),
            width: size,
            height: size,
            fill: determineCubArucoFillColor(cubaruco),
            stroke: cubaruco.location_type === "shelf" ? STROKE_UNFILTERED_COLOR : CUBARUCO_TRACKING_STROKE_COLOR,
            strokeWidth: 3,
            hasControls: false,
            lockMovementX: true,
            lockMovementY: true,
            borderScaleFactor: 3,
            borderColor: "orange",
            hoverCursor: "crosshair"
        })
        canvas.current.add(c)
    }, [])

    useEffect(() => {
        if (!storeId.match(/^(\d+,)*(\d+)$/)) {
            navigate("/404")
        }
    }, [storeId, navigate])
    
    useEffect(() => {
        props.setSelectedTab("cubarucos")
    })

    useEffect(() => {
        setSelectedCubAruco(null)
    }, [location])

    //Set Canvas with Store Image
    useEffect(() => {
        canvas.current = InitCanvas(DEFAULT_CANVAS_RESOLUTION_X, DEFAULT_CANVAS_RESOLUTION_Y)
        FetchAndCreateBackgroundStoreImage(canvas.current, storeId, props.imgType, token, userenv)
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/cubarucos`,
            {
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                })
            })
            .then(response => { 
                if(response.status === 401){
                    setToken(null); 
                }else return response.json();})
            .then(data => {
                let numOfTrackingPlacements = data.filter(c => c.location_type === "tracking")
                let numOfShelfPlacements = data.length - numOfTrackingPlacements.length
                setNumOfPlacements(data.length)
                setNumOfSamples(numOfTrackingPlacements.length + numOfShelfPlacements * 2)
                data.forEach(addCubArucoSquare)
            })
            .catch(err => console.log(err))

        canvas.current.on("selection:updated", (e) => {
            setSelectedCubAruco(e.selected[0])
        })

        canvas.current.on("selection:created", (e) => {
            setSelectedCubAruco(e.selected[0])
        })

        canvas.current.on("selection:cleared", (e) => {
            setSelectedCubAruco(null)
        })

        // destroy fabric on unmount
        return () => {
            canvas.current.dispose();
            canvas.current = null;
        };

    }, [storeId, props.imgType, token, setToken, addCubArucoSquare, userenv])

    useEffect(() => {
        const interval = setInterval(() => {
            fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/cubarucos`,
                {
                    headers: new Headers({
                        "Authorization": "Bearer " + token
                    })
                })
                .then(response => { 
                    if(response.status === 401){
                        setToken(null); 
                    }else return response.json();})
                .then(data => {
                    let cubArucoMap = new Map(data.map((c) => [c.cubaruco_id, c]));
                    let canvasObjs = canvas.current.getObjects()
                    for (let i = 0; i < canvasObjs.length; i++) {
                        if ("cubaruco_id" in canvasObjs[i]) {
                            canvasObjs[i].set("fill", determineCubArucoFillColor(cubArucoMap.get(canvasObjs[i])))
                        }
                    }
                    canvas.current.renderAll()
                }
                ).catch(error => console.log(error))
        }, 5000);
        return () => clearInterval(interval);
    }, [storeId, token, setToken, userenv]);


    const changeSelectedCubArucoSampledValue = (newVal, sampleNum) => {
        if(sampleNum === 1) selectedCubAruco.sampled = newVal
        if(sampleNum === 2) selectedCubAruco.double_sampled = newVal

        if ((selectedCubAruco.location_type === "tracking" && newVal) || 
            (selectedCubAruco.location_type === "shelf" && newVal && sampleNum === 2)) {
            selectedCubAruco.set("fill", FILL_COMPLETED_COLOR)
        }else {
            selectedCubAruco.set("fill", FILL_UNFILTERED_COLOR)
        }
        canvas.current.renderAll()
    }

    const removeSelectedCubAruco = () => {
        canvas.current.remove(canvas.current.getActiveObject());
    }

    return (
        <div className="App">
            <header className="App-header">
                <h2 className="mt-3"><br />CubAruco Placements</h2>
            </header>
            <div className="App-filter-options ">
                    <Row className="align-items-center">
                        <Col xs="auto">
                            <Form.Label className="mt-2">{numOfPlacements} placements  / {numOfSamples} samples</Form.Label>
                        </Col>
                    </Row>
            </div>
            <div className="App-body">
            <div className="canvas-container">
                    <canvas id="canvas" />
                </div>
                <div className="CardList">
                    {selectedCubAruco && <CubAruco key={selectedCubAruco.cubaruco_id} storeId={storeId}
                        selectedCubAruco={selectedCubAruco}
                        changeSelectedCubArucoSampledValue={changeSelectedCubArucoSampledValue}
                        removeSelectedCubAruco={removeSelectedCubAruco} />}
                    {!selectedCubAruco &&
                        <Card className="App-labels mb-5">
                            <Card.Body className="Card-body">
                                <Card.Title className="mb-4">Placement Details</Card.Title>
                                <Alert variant="primary">
                                    No placement selected.
                                </Alert>
                            </Card.Body>
                        </Card>
                    }
                </div>
            </div>
        </div>
    )
}