
export default function CameraArrow(props) {
    return (
        <div>
            <div className="arrow" style={{ transform:`rotate(${props.yaw+225}deg)`}}>
                <div className="line"></div>
                <div className="zero-line"></div>
            </div>
        </div>
    )
}