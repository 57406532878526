import React, { useMemo } from "react"
import {createContext, useEffect, useState} from "react"
import { BuildSenseiAuthURL } from "../helpers/URLHelper"

export const UserContext = createContext(null)

const b64DecodeUnicode = (str) => {
    // https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
    return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
  
const getExpirationSecondsRemaining = (exp) => {
    const current = new Date().getTime();
    return (exp * 1000 - current) / 1000;
}

const parseJwtPayload = (token) => {
    if(token === null || token === undefined || token === "undefined"){
        return null;
    }
    return JSON.parse(b64DecodeUnicode(token.split('.')[1]));
}

const EXPIRATION_REFRESH_MARGIN_SEC = 10

export const UserProvider = (props) => {
    
    const [token, setToken] = useState(localStorage.getItem("sensei-user-token"))
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem("sensei-refresh-token"))
    const username = localStorage.getItem("sensei-user-name")
    const userid = localStorage.getItem("sensei-user-id")
    const userenv = localStorage.getItem("sensei-user-env")
    const providerValue = useMemo(() => ({username, token, userid, userenv, refreshToken, setToken, setRefreshToken}), [username, userid, userenv, token, refreshToken, setToken, setRefreshToken])

    const logout = () => {
        setToken(null)
        setRefreshToken(null)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setToken(localStorage.getItem("sensei-user-token"))
            let decodedToken = parseJwtPayload(token)
            if(decodedToken !== null)
            {
                let exp_sec = (getExpirationSecondsRemaining(decodedToken.exp))
                if (exp_sec < EXPIRATION_REFRESH_MARGIN_SEC)
                {
                    let refreshBody = {}
                    refreshBody.refreshToken = refreshToken
                    fetch(`${BuildSenseiAuthURL(userenv)}/refresh`, 
                        { 
                          method: "POST",
                          headers: new Headers(
                          {
                                "Content-type": "application/json",
                          }),
                          body: JSON.stringify(refreshBody)
                        }).then(response => {
                            if(!response.ok){
                                logout()
                            }
                            return response.json()
                        }).then(data => {
                            setToken(data.accessToken); 
                            localStorage.setItem("sensei-user-token", data.accessToken)
                        })
                        .catch(err => console.log(err))
                } 
                else if (exp_sec < 0) 
                {
                   logout()
                }
            }else
            {
                logout()
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [refreshToken, token, userenv]);
    return (
        <UserContext.Provider value={providerValue}>
            {props.children}
        </UserContext.Provider>
    )
} 