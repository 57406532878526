import "bootstrap/dist/css/bootstrap.min.css";
import { Alert, Card, ListGroup, ListGroupItem, Button, Modal, Form, ModalDialog } from "react-bootstrap"
import { FaTrash } from "react-icons/fa"
import { FaEdit } from "react-icons/fa"
import { UserContext } from "../context/UserContext"
import { useForm } from "react-hook-form"
import { useState } from "react";
import React, { useContext } from "react"
import { TbReplaceFilled } from "react-icons/tb";

import Draggable from "react-draggable";

const GENERIC_ERROR_MESSAGE = "Oops... Something went wrong!";

class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".modal-header"><ModalDialog {...this.props} />
        </Draggable>
    }
}
export default function ScaleGateway(props) {
    const { token, userenv, setToken } = useContext(UserContext)
    const [showEditGatewayModal, setShowEditGatewayModal] = useState(false)
    const [showRemoveGatewayModal, setShowRemoveGatewayModal] = useState(false)
    const [showReplaceScaleGatewayModal, setShowReplaceScaleGatewayModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState(GENERIC_ERROR_MESSAGE)
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [replaceScaleGatewayErrorMessage, setReplaceScaleGatewayErrorMessage] = useState("")
    const { register, handleSubmit, reset, setValue } = useForm()
    const { register: registerFormReplaceScaleGateway, handleSubmit: handleSubmitFormReplaceScaleGateway, reset: resetFormReplaceScaleGateway } = useForm()


    const handleClose = () => { setShowErrorAlert(false); reset(); setShowEditGatewayModal(false); };
    const handleShow = () => { reset(); setShowEditGatewayModal(true) };
    const handleCloseReplaceScaleGatewayModal = () => { setShowErrorAlert(false); resetFormReplaceScaleGateway(); setShowReplaceScaleGatewayModal(false); };


    const onSubmit = (data) => {
        var isResponseOK = false
        
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/gondolas/${props.gondola_id}/gateways/${props.gateway.scale_gateway_id}`,
            {
                method: 'PATCH',
                body: JSON.stringify(data),
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-type": "application/json"
                })
            }).then(response => {
                if (response.status === 401) setToken(null)
                if (response.ok) {
                    isResponseOK = true
                } else {
                    setShowErrorAlert(true)
                }
                return response.json()
            }).then(response_json => {
                if (isResponseOK) {
                    props.fetchGondolaInfo()
                    handleClose();
                } else {
                    if (Array.isArray(response_json.detail)) {
                        setErrorMessage(response_json.detail[0].msg)
                    } else {
                        setErrorMessage(response_json.detail)
                    }
                }
            }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    const onClickRemove = (e) => {
        var isResponseOK = false

        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}` +
            `/gondolas/${props.gondola_id}/gateways/${props.gateway.scale_gateway_id}?force_delete=false`, {
            method: 'DELETE',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        }).then(response => {
            if (response.ok) {
                isResponseOK = true
                props.fetchGondolaInfo()
                setShowRemoveGatewayModal(false)
            }
            return response.json()
        }).then(response_json => {
            if(!isResponseOK) {
                setErrorMessage(response_json.detail)
                setShowErrorAlert(true)            
            }
        }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    const onSubmitReplaceScaleGateway = (data) => {
        var isResponseOK = false
        console.log(data)
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/gondolas/${props.gondola_id}/` +
            `gateways/${props.gateway.scale_gateway_id}/replace`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-type": "application/json"
                })
            })
            .then(response => {
                if (response.status === 401) setToken(null)
                if (response.ok) {
                    props.fetchGondolaInfo()
                    handleCloseReplaceScaleGatewayModal()
                } else {
                    setShowErrorAlert(true)
                }
                return response.json()
            }).then(response_json => {
                if (!isResponseOK) {
                    if (Array.isArray(response_json.detail)) {
                        setReplaceScaleGatewayErrorMessage(response_json.detail[0].msg)
                    } else {
                        setReplaceScaleGatewayErrorMessage(response_json.detail)
                    }
                }
            }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    return (
        <div>
            <Card className="App-labels mb-5 ">
                <Card.Body className="Card-body">
                    <Card.Title className="mb-1">Gateway  {props.gateway.backend_id === null ? 
                                                           props.gateway.scale_gateway_id : props.gateway.backend_id}
                    &nbsp;&nbsp;&nbsp;
                    <Button variant="primary" size="sm" onClick={handleShow}>
                        <FaEdit />
                    </Button>
                    &nbsp;
                    <Button variant="warning" onClick={() => setShowReplaceScaleGatewayModal(true)} size="sm">
                        <TbReplaceFilled />
                    </Button>
                    &nbsp;
                    <Button variant="danger" size="sm" onClick={() => setShowRemoveGatewayModal(true)}>
                        <FaTrash />
                    </Button>
                    </Card.Title>
                    
                    <Form>
                        <ListGroup className="mt-3 mb-3">
                            <ListGroupItem>
                                Backend Id: &nbsp; {props.gateway.backend_id}
                            </ListGroupItem>
                            <ListGroupItem>
                                MAC Address: &nbsp; {props.gateway.mac_address}
                            </ListGroupItem>
                            <ListGroupItem>
                                IP Address: &nbsp; {props.gateway.ip_address}
                            </ListGroupItem>
                            <ListGroupItem>
                                Connected: &nbsp; &nbsp;
                                <Form.Check inline disabled checked={props.gateway.connection_check} type="checkbox" />
                            </ListGroupItem>
                            <ListGroupItem>
                                Gondolas Count: &nbsp; {props.gateway.associated_gondolas.length}
                            </ListGroupItem>
                        </ListGroup>
                    </Form>
                </Card.Body>
            </Card>

            <Modal dialogAs={DraggableModalDialog} backdrop="static" show={showEditGatewayModal} onHide={handleClose}>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Gateway Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="form.EditGateway">
                            {setValue("ip_address", props.gateway.ip_address)}
                            <Form.Label>IP Address:</Form.Label>
                            <Form.Control  type="text" placeholder="<ip_address>" autoFocus
                                {...register("ip_address")}/>
                        </Form.Group>
                        {showErrorAlert &&
                            <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {errorMessage}
                            </Alert>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal backdrop="static" show={showRemoveGatewayModal} onHide={() => {setShowErrorAlert(false); setShowRemoveGatewayModal(false)}}>
                   <Modal.Header closeButton>
                        <Modal.Title>Remove Gateway</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    Are you sure you want to remove this gateway ?
                    {showErrorAlert &&
                        <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                        {errorMessage}
                    </Alert>}
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <Button type="submit" onClick={onClickRemove} variant="primary">
                            Yes
                        </Button>
                        <Button type="submit" onClick={() => setShowRemoveGatewayModal(false)} variant="secondary">
                            No
                        </Button>
                    </Modal.Footer>
            </Modal>

            <Modal dialogAs={DraggableModalDialog} backdrop="static" show={showReplaceScaleGatewayModal} onHide={handleCloseReplaceScaleGatewayModal}>
                <Form noValidate onSubmit={handleSubmitFormReplaceScaleGateway(onSubmitReplaceScaleGateway)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Replace Scale Gateway</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="form.ReplaceScale">
                            <Form.Label>Replacement Mac Address:</Form.Label>
                            <Form.Control type="text" placeholder="<mac_address>" autoFocus
                                {...registerFormReplaceScaleGateway("replacement_mac_address", { required: true })} />
                        </Form.Group>
                        {showErrorAlert &&
                            <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {replaceScaleGatewayErrorMessage}
                            </Alert>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}