import React, { useContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form"
import { useState, useRef } from "react";
import { Alert, Card, Button, Form} from "react-bootstrap"
import { UserContext } from "../context/UserContext";
import { BuildSenseiBackendAPIURL } from "../helpers/URLHelper";

const GENERIC_ERROR_MESSAGE = "Oops... Something went wrong!";

export default function ChangePassword(props) {
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false)
    const [errorMessage, setErrorMessage] = useState("-")
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const { token, userenv } = useContext(UserContext)
    
    const { register, handleSubmit, formState: { errors }, watch } = useForm()
    const password = useRef({});
    password.current = watch("newPassword", "");


    const onSubmit = (data) => {
        delete(data.new_password_confirmation)
        fetch(`${BuildSenseiBackendAPIURL(userenv)}/users/own/password`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: new Headers({
                    "Authorization" : "Bearer " + token,
                    "Content-type":"application/json"
                })})
            .then(response => {
                if (!response.ok) {
                    setShowErrorAlert(true)
                    setErrorMessage(GENERIC_ERROR_MESSAGE)
                }else{
                    setIsPasswordUpdated(true)
                }
            })
            .catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    return (
        <div className="App-login-body mt-5">
            <Card className="App-labels">
                <Card.Body className="Login-card-body">
                    <Card.Title className="mb-4">Change Password</Card.Title>
                    {isPasswordUpdated ?
                        <Alert className="mb-4" variant="success">
                            Password was successfully updated!
                        </Alert>
                        :
                        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group controlId="form.Username">
                            <Form.Label>Current Password:</Form.Label>
                            <Form.Control type="password" placeholder="Current Password" autoFocus  {...register("currentPassword", { required: true })} isInvalid={errors.currentPassword} />
                            <Form.Control.Feedback type="invalid">
                                Please fill in a valid value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group  className="mt-4 mb-4" controlId="form.Password">
                            <Form.Label>New Password:</Form.Label>
                            <Form.Control type="password"  placeholder="New Password"  {...register("newPassword", { required: true, 
                                                                                                                                minLength: { value: 8, message: "Password must have at least 8 characters"}})}
                                                                                                                                isInvalid={errors.newPassword} />
                            {errors.newPassword &&
                            <Form.Control.Feedback type="invalid">
                                {errors.newPassword.message}
                            </Form.Control.Feedback>}
                        </Form.Group>
                        <Form.Group  className="mt-4 mb-4" controlId="form.Password">
                            <Form.Label>Confirm New Password:</Form.Label>
                            <Form.Control type="password"  placeholder="New Password"  
                                                           {...register("new_password_confirmation", { validate: value => value === password.current || "Passwords do not match"})}
                                                           isInvalid={errors.new_password_confirmation} />
                            {errors.new_password_confirmation &&
                            <Form.Control.Feedback type="invalid">
                                {errors.new_password_confirmation.message}
                            </Form.Control.Feedback>}
                        </Form.Group>
                        {showErrorAlert && 
                            <Alert className="mb-4" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {errorMessage}
                            </Alert>}
                        <Button type="submit" variant="primary">
                            Submit
                       </Button>
                    </Form>
  
                    }
                    </Card.Body>
            </Card>
        </div>
    )
}