import React, { useContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { fabric } from 'fabric'
import { useEffect, useRef, useState, useCallback } from "react";
import { Card, Alert, Form, Button, Row, Col, Modal } from "react-bootstrap"
import Camera from '../components/Camera'
import NetworkSwitch from "../components/NetworkSwitch";
import { useParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form"
import { UserContext } from "../context/UserContext";
import {
    FetchAndCreateBackgroundStoreImage,
    InitCanvas,
    STROKE_FILTERED_COLOR,
    STROKE_UNFILTERED_COLOR,
    FILL_COMPLETED_COLOR,
    FILL_FILTERED_COLOR,
    FILL_UNFILTERED_COLOR,
    CAMERA_CIRCLE_TEXT_FILL_COLOR_FILTERED,
    CAMERA_CIRCLE_TEXT_FILL_COLOR_UNFILTERED,
    DEFAULT_CANVAS_RESOLUTION_X,
    DEFAULT_CANVAS_RESOLUTION_Y
} from "../helpers/FabricJSHelpers";

export default function Cameras(props) {
    const canvas = useRef(null)
    const inputFileRef = useRef(null)
    const [totalCamerasNum, setTotalCamerasNum] = useState(0)
    const [totalCamerasInPublicBackendNum, setTotalCamerasInPublicBackendNum] = useState(null)
    const [totalCamerasFilteredNum, setTotalCamerasFilteredNum] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedCamerasCircle, setSelectedCamerasCircle] = useState(null)
    const [selectedSwitchSquare, setSelectedSwitchSquare] = useState(null)
    const [showCameraIPScanModal, setShowCameraIPScanModal] = useState(false)
    const [showCameraFilterModal, setShowCameraFilterModal] = useState(false)
    const [camerasConnectedNum, setCamerasConnectedNum] = useState(0)
    const [camerasDisconnectedNum, setCamerasDisconnectedNum] = useState(0)
    const [camerasUpdatedNum, setCamerasUpdatedNum] = useState(0)
    const [cameraScanError, setCameraScanError] = useState(false)
    const [cameraScanErrorMessage, setCameraScanErrorMessage] = useState("")
    const [multipleSelection, setMultipleSelection] = useState(false)
    const [totalCamerasSelected, setTotalCamerasSelected] = useState(0)
    const [showEmptyCameraCircles, setShowEmptyCameraCircles] = useState(false)
    const [showInconsistentCamerasAlert, setShowInconsistentCamerasAlert] = useState(false)

    const { token, userenv, setToken } = useContext(UserContext)
    const { register, handleSubmit, reset } = useForm()

    const { storeId } = useParams()

    const navigate = useNavigate()
    useEffect(() => {
        if (!storeId.match(/^(\d+,)*(\d+)$/)) {
            navigate("/404")
        }
    }, [storeId, navigate])

    const isCameraCircle = useCallback((obj) => {
        return obj instanceof fabric.Group && "camera_ids" in obj
    }, []);

    const getCameraNumberInsideCircle = useCallback((camera) => {
        if (Array.from(searchParams).length > 0) {
            return camera.camera_filtered_num
        } else {
            return camera.camera_ids.length
        }
    }, [searchParams]);

    const addCameraCircle = useCallback((camera) => {
        const radius = 6
        const arrow_size = 17
        const x = Math.round(camera.pos_x)
        const y = Math.round(camera.pos_y)

        let c = new fabric.Circle({
            radius: radius,
            fill: camera.completed ? FILL_COMPLETED_COLOR : FILL_UNFILTERED_COLOR,
            stroke: STROKE_UNFILTERED_COLOR,
            angle: 45,
            strokeWidth: 3,
            originX: "center",
            originY: "center",

        })

        let text = new fabric.Text(String(getCameraNumberInsideCircle(camera)), {
            fontSize: 10,
            fontFamily: "Arial",
            fill: "white",
            originX: "center",
            originY: "center"
        })

        let group = new fabric.Group([c, text], {
            camera_ids: camera.camera_ids,
            filtered: camera.filtered,
            camera_filtered_num: camera.camera_filtered_num,
            img_pos_x: camera.pos_x,
            img_pos_y: camera.pos_y,
            originX: "center",
            originY: "center",
            left: x,
            top: y,
            lockMovementX: true,
            lockMovementY: true,
            hasControls: false,
            borderScaleFactor: 3,
            borderColor: "orange",
            hoverCursor: "crosshair"
        })

        camera.camera_yaws.forEach((yaw) => {
            let line = new fabric.Line([x, y, x + arrow_size, y], {
                stroke: 'black',
                hasControls: false,
                originX: "left",
                originY: "center",
                lockMovementX: true,
                lockMovementY: true,
                selectable: false,
                angle: yaw,
                hoverCursor: "default",
                strokeWidth: 2
            })
            group.addWithUpdate(line)
            line.sendToBack()
        })

        canvas.current.add(group)
    }, [getCameraNumberInsideCircle]);

    const updateCircleFillColor = useCallback((cameraCircle) => {
        let canvasObjs = canvas.current.getObjects()
        for (let i = 0; i < canvasObjs.length; i++) {
            if (isCameraCircle(canvasObjs[i]) && canvasObjs[i].camera_ids[0] === cameraCircle.camera_ids[0]) {
                if (cameraCircle.filtered) {
                    canvasObjs[i].set("camera_filtered_num", cameraCircle.camera_filtered_num)
                    canvasObjs[i].set("filtered", cameraCircle.filtered)

                    let circleItem = canvasObjs[i].getObjects().filter(obj => obj instanceof fabric.Circle)[0]
                    circleItem.set("stroke", STROKE_FILTERED_COLOR)
                    circleItem.set("fill", FILL_FILTERED_COLOR)

                    let circleText = canvasObjs[i].getObjects().filter(obj => obj instanceof fabric.Text)[0]
                    circleText.set("fill", CAMERA_CIRCLE_TEXT_FILL_COLOR_FILTERED)
                    circleText.set("text", String(cameraCircle.camera_filtered_num))
                } else {
                    canvasObjs[i].set("camera_filtered_num", cameraCircle.camera_filtered_num)
                    canvasObjs[i].set("filtered", cameraCircle.filtered)

                    let circleItem = canvasObjs[i].getObjects().filter(obj => obj instanceof fabric.Circle)[0]
                    circleItem.set("stroke", STROKE_UNFILTERED_COLOR)
                    if (cameraCircle.completed) circleItem.set("fill", FILL_COMPLETED_COLOR)
                    else circleItem.set("fill", FILL_UNFILTERED_COLOR)

                    let circleText = canvasObjs[i].getObjects().filter(obj => obj instanceof fabric.Text)[0]
                    circleText.set("fill", CAMERA_CIRCLE_TEXT_FILL_COLOR_UNFILTERED)

                    if (Array.from(searchParams).length > 0) {
                        circleText.set("text", String("0"))
                        if (showEmptyCameraCircles) {
                            canvasObjs[i].set("visible", true)
                        } else {
                            canvasObjs[i].set("visible", false)
                        }
                    } else {
                        circleText.set("text", String(cameraCircle.camera_ids.length))
                    }
                }
            }
        }
    }, [isCameraCircle, searchParams, showEmptyCameraCircles]);

    const fetchAndUpdateCamerasCircleColor = useCallback(() => {
        let URL = `${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/cameras`
        if (Array.from(searchParams).length > 0) {
            URL = URL + "?" + searchParams.toString()
        }

        fetch(URL, {
            headers: new Headers({
                "Authorization": "Bearer " + token,
            })
        })
            .then(response => {
                if (response.status === 401) {
                    setToken(null);
                } else return response.json();
            })
            .then(data => {
                setTotalCamerasFilteredNum(data.total_cameras_filtered);
                data.camera_circles.forEach(updateCircleFillColor);
                canvas.current.renderAll()
            })
            .catch(err => console.log(err))
    }, [searchParams, token, userenv, setToken, storeId, updateCircleFillColor]);

    useEffect(() => {
        props.setSelectedTab("cameras")
    })

    const location = useLocation();

    useEffect(() => {
        setSelectedCamerasCircle(null)
    }, [location])

    const addSwitchSquare = useCallback((networkSwitch) => {
        let s = new fabric.Rect({
            network_switch_id: networkSwitch.network_switch_id,
            associated_camera_ids: networkSwitch.camera_ids,
            left: Math.round(networkSwitch.img_pos_x),
            top: Math.round(networkSwitch.img_pos_y),
            originX: "center",
            originY: "center",
            width: 15,
            height: 15,
            fill: "white",
            stroke: "black",
            strokeWidth: 3,
            hasControls: false,
            lockMovementX: true,
            lockMovementY: true,
            borderScaleFactor: 3,
            borderColor: "cyan",
            hoverCursor: "crosshair"
        })
        canvas.current.add(s)
    }, []);

    const clearCamerasAndSwitches = useCallback(() => {
        canvas.current.remove(...canvas.current.getObjects());
    }, []);

    const fetchAndDrawCamerasAndSwitches = useCallback(() => {
        let URL = `${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/cameras`
        fetch(URL,
            {
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                })
            })
            .then(response => {
                if (response.status === 401) {
                    setToken(null);
                } else return response.json();
            })
            .then(data => { 
                setTotalCamerasNum(data.total_cameras);
                setTotalCamerasInPublicBackendNum(data.total_cameras_in_backend);

                if(data.total_cameras_in_backend !== null && data.total_cameras_in_backend !== data.total_cameras){
                    setShowInconsistentCamerasAlert(true)
                }else{
                    setShowInconsistentCamerasAlert(false)
                }

                data.camera_circles.forEach(addCameraCircle);
                data.network_switches.forEach(addSwitchSquare) })
            .catch(err => console.log(err))
    }, [storeId, token, userenv, addCameraCircle, addSwitchSquare, setToken])

    //Set Canvas with Store Image
    useEffect(() => {
        canvas.current = InitCanvas(DEFAULT_CANVAS_RESOLUTION_X, DEFAULT_CANVAS_RESOLUTION_Y, true)
        FetchAndCreateBackgroundStoreImage(canvas.current, storeId, props.imgType, token, userenv)
        fetchAndDrawCamerasAndSwitches()

        canvas.current.on("selection:updated", (e) => {
            removeSquareFromCameras()
            if (isCameraCircle(e.selected[0])) {
                setSelectedCamerasCircle(e.selected[0])
                setSelectedSwitchSquare(null)
            } else //is switch square
            {
                setSelectedCamerasCircle(null)
                setSelectedSwitchSquare(e.selected[0])
                e.selected[0].associated_camera_ids.forEach(addSquareToCamera)
            }
        })

        canvas.current.on("selection:created", (e) => {
            removeSquareFromCameras()

            if (e.selected.length > 1) {
                setMultipleSelection(true)

                fabric.Group.prototype.hasControls = false
                fabric.Group.prototype.lockMovementX = true
                fabric.Group.prototype.lockMovementY = true

                let totalCamerasSelected = 0
                for (let i = 0; i < e.selected.length; i++) {
                    if (isCameraCircle(e.selected[i])) {
                        totalCamerasSelected += getCameraNumberInsideCircle(e.selected[i])
                    }
                }
                setTotalCamerasSelected(totalCamerasSelected)
                return
            } else {
                setMultipleSelection(false)
            }
            if (isCameraCircle(e.selected[0])) {
                setSelectedCamerasCircle(e.selected[0])
                setSelectedSwitchSquare(null)
            } else {
                setSelectedCamerasCircle(null)
                setSelectedSwitchSquare(e.selected[0])
                e.selected[0].associated_camera_ids.forEach(addSquareToCamera)
            }
        })

        canvas.current.on("selection:cleared", (e) => {
            setSelectedCamerasCircle(null)
            setSelectedSwitchSquare(null)
            removeSquareFromCameras()
        })

        // destroy fabric on unmount
        return () => {
            canvas.current.dispose();
            canvas.current = null;
        };

    }, [storeId, props.imgType, token, setToken, userenv, isCameraCircle, getCameraNumberInsideCircle, addCameraCircle, fetchAndDrawCamerasAndSwitches])

    useEffect(() => {
        const interval = setInterval(fetchAndUpdateCamerasCircleColor, 5000);
        return () => clearInterval(interval);
    }, [storeId, fetchAndUpdateCamerasCircleColor, searchParams, token]);

    useEffect(fetchAndUpdateCamerasCircleColor, [storeId, fetchAndUpdateCamerasCircleColor, token, searchParams])

    const setSelectedCameraCircleColor = (color) => {
        canvas.current.getActiveObject().set("fill", color)
        canvas.current.renderAll()
    }

    const addSquareToCamera = (camera_id) => {
        let canvasObjs = canvas.current.getObjects()
        for (let i = 0; i < canvasObjs.length; i++) {
            if (canvasObjs[i] instanceof fabric.Group && "camera_ids" in canvasObjs[i]) {
                if (canvasObjs[i].camera_ids.includes(camera_id)) {
                    let s = new fabric.Rect({
                        isCameraSquare: true,
                        left: canvasObjs[i].left,
                        top: canvasObjs[i].top,
                        originX: "center",
                        originY: "center",
                        width: 20,
                        height: 20,
                        fill: "transparent",
                        stroke: "cyan",
                        strokeWidth: 3,
                        selectable: false,
                    })
                    canvas.current.add(s)
                    s.sendToBack()
                }
            }
        }
    }

    const removeSquareFromCameras = () => {
        let canvasObjs = canvas.current.getObjects()
        for (let i = 0; i < canvasObjs.length; i++) {
            if ("isCameraSquare" in canvasObjs[i]) {
                canvas.current.remove(canvasObjs[i])
            }
        }
    }

    const onClickResetFilter = () => {
        setSearchParams(new URLSearchParams())
        let canvasObjs = canvas.current.getObjects()
        for (let i = 0; i < canvasObjs.length; i++) {
            if ("camera_ids" in canvasObjs[i]) {
                canvasObjs[i].set("stroke", STROKE_UNFILTERED_COLOR)
                canvasObjs[i].set("fill", FILL_UNFILTERED_COLOR)
            }
        }
        canvas.current.renderAll()
    }

    const onSubmitFilter = (data) => {
        let newSearchParams = {}
        for (const [key, value] of Object.entries(data)) {
            if (value !== null && value !== undefined && value !== "" && value !== "-") {
                newSearchParams[key] = value
            }
            setSearchParams(newSearchParams)
        }
    }

    const handleFileSelected = (event) => {
        var fileData = new FormData()
        fileData.append("ip_scan_file", inputFileRef.current?.files[0])
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/cameras/ip-scan-file`,
            {
                method: 'POST',
                body: fileData,
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "ngrok-skip-browser-warning": "1234"
                })
            }).then(response => {
                if (response.status === 401) setToken(null)
                if (!response.ok) {
                    setCameraScanError(true)
                }
                return response.json()
            })
            .then(data => {
                if (!cameraScanError) {
                    setCamerasConnectedNum(data.total_connected)
                    setCamerasDisconnectedNum(data.total_disconnected)
                    setCamerasUpdatedNum(data.total_updated)
                } else {
                    setCameraScanErrorMessage(data.detail)
                }
                setShowCameraIPScanModal(true)
            })
            .catch(err => console.log(err))
        // Reset file input value so that the same file can be selected
        event.target.value = null
    }


    return (
        <div className="App">
            <header className="App-header">
                <h2 className="mt-5">Cameras</h2>
            </header>
            <div className="App-filter-options ">
                <Row className="align-items-center">

                    <Col xs="auto">
                        <Button variant="primary" size="sm" onClick={() => { reset(); setShowCameraFilterModal(true) }}>
                            Filter
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button onClick={onClickResetFilter} size="sm" variant="warning">
                            Reset
                        </Button>
                    </Col>
                    <Col xs="auto">
                        {Array.from(searchParams).length > 0 &&
                            <Form.Label className="mt-2">Filters: ('{searchParams.toString().replace('&', ', ')}') &nbsp;</Form.Label>
                        }
                        {Array.from(searchParams).length === 0 ?
                            <Form.Label className="mt-2">{totalCamerasNum} cameras</Form.Label>
                            :
                            <Form.Label className="mt-2">{totalCamerasFilteredNum}/{totalCamerasNum} cameras</Form.Label>
                        }
                    </Col>
                    <Col xs="auto">
                        <input className="d-none" onChange={handleFileSelected} ref={inputFileRef} type="file" />
                        <Button onClick={() => inputFileRef.current?.click()} size="sm" variant="secondary">
                            Upload IP Scan
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Show Empty Camera Locations"
                            value={showEmptyCameraCircles.toString()}
                            onChange={(e) => {
                                setShowEmptyCameraCircles(e.target.checked)
                            }} />
                    </Col>
                </Row>

            </div>

            <div className="App-body">
                <div className="canvas-container">
                    <canvas id="canvas" />
                </div>
                <div className="CardList">
                    {showInconsistentCamerasAlert &&
                        <Alert className="App-labels" variant="danger" onClose={() => setShowInconsistentCamerasAlert(false)} dismissible>
                            Inconsistent Camera Number: {totalCamerasNum} in deployment and {totalCamerasInPublicBackendNum} in database. 
                        </Alert>}
                    {!multipleSelection && selectedCamerasCircle &&
                        selectedCamerasCircle.camera_ids.map(cameraId => <Camera key={cameraId} storeId={storeId} cameraId={cameraId}
                            setSelectedCameraCircleColor={setSelectedCameraCircleColor} posX={selectedCamerasCircle.img_pos_x} posY={selectedCamerasCircle.img_pos_y}
                            clearCamerasFromCanvas={clearCamerasAndSwitches}
                            fetchAndDrawCameras={fetchAndDrawCamerasAndSwitches} />)}

                    {!multipleSelection && selectedSwitchSquare &&
                        <NetworkSwitch key={selectedSwitchSquare.network_switch_id} storeId={storeId} networkSwitchId={selectedSwitchSquare.network_switch_id} />}

                    {!multipleSelection && !selectedSwitchSquare && !selectedCamerasCircle &&
                        <Card className="App-labels mb-5">
                            <Card.Body className="Card-body">
                                <Card.Title className="mb-4">Camera Details</Card.Title>
                                <Alert variant="primary">
                                    No camera selected.
                                </Alert>
                            </Card.Body>
                        </Card>
                    }

                    {multipleSelection &&
                        <Card className="App-labels mb-5">
                            <Card.Body className="Card-body">
                                <Card.Title className="mb-4">Camera Group Details</Card.Title>
                                <Alert variant="primary">
                                    You selected a total of {totalCamerasSelected} cameras.
                                </Alert>
                            </Card.Body>
                        </Card>
                    }
                </div>
            </div>
            <Modal backdrop="static" show={showCameraIPScanModal} onHide={() => setShowCameraIPScanModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>IP Scan Result</Modal.Title>
                </Modal.Header>
                {cameraScanError ?
                    <Modal.Body>
                        <Alert className="mt-3" variant="danger" >
                            An error occurred when parsing the IPs file!
                        </Alert>
                        <p>Error: {cameraScanErrorMessage}</p>
                    </Modal.Body>
                    :
                    <Modal.Body>
                        <Alert className="mt-3" variant="success" >
                            The file was parsed successfully!
                        </Alert>
                        <p>Number of cameras updated: {camerasUpdatedNum}</p>
                        <p>Number of cameras connected: {camerasConnectedNum}</p>
                        <p>Number of cameras disconnected: {camerasDisconnectedNum}</p>
                    </Modal.Body>
                }
                <Modal.Footer>
                    <Button onClick={() => setShowCameraIPScanModal(false)} variant="primary">
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop="static" show={showCameraFilterModal} onHide={() => setShowCameraFilterModal(false)}>
                <Form noValidate onSubmit={handleSubmit(onSubmitFilter)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter Cameras</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="form.LensFilter">
                            <Form.Label>Lens</Form.Label>
                            <Form.Select size="sm" {...register("lens")}>
                                <option value="-">-</option>
                                <option value="2.1">2.1</option>
                                <option value="2.8">2.8</option>
                                <option value="3.6">3.6</option>
                                <option value="4.0">4.0</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.HeightFilter">
                            <Form.Label>Height</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="<height>"
                                {...register("height")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.RolesFilter">
                            <Form.Label>Roles</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="<roles>"
                                {...register("roles")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.MacAddressFilter">
                            <Form.Label>Mac Address</Form.Label>
                            <Form.Control inline size="sm" type="text" placeholder="<mac_address>"
                                {...register("mac_address")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.IPAddressFilter">
                            <Form.Label>IP Address</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="<ip_address>"
                                {...register("ip_address")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.IPAddressFilter">
                            <Form.Label>Backend Id</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="<backend_id>"
                                {...register("backend_id")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.Scanned">
                            <Form.Label >Scanned: &nbsp; </Form.Label>
                            <Form.Check inline label="Any" value="-" name="scanned" type="radio" defaultChecked {...register("scanned")} />
                            <Form.Check inline label="True" value={true} name="scanned" type="radio" {...register("scanned")} />
                            <Form.Check inline label="False" value={false} name="scanned" type="radio" {...register("scanned")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.ConnectionCheck">
                            <Form.Label >Connection Check: &nbsp; </Form.Label>
                            <Form.Check inline label="Any" value="-" name="connection_check" type="radio" defaultChecked {...register("connection_check")} />
                            <Form.Check inline label="True" value={true} name="connection_check" type="radio" {...register("connection_check")} />
                            <Form.Check inline label="False" value={false} name="connection_check" type="radio" {...register("connection_check")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.QACheck">
                            <Form.Label >QA Check: &nbsp; </Form.Label>
                            <Form.Check inline label="Any" value="-" name="qa_check" type="radio" defaultChecked {...register("qa_check")} />
                            <Form.Check inline label="True" value={true} name="qa_check" type="radio" {...register("qa_check")} />
                            <Form.Check inline label="False" value={false} name="qa_check" type="radio" {...register("qa_check")} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShowCameraFilterModal(false)} type="submit" variant="primary">
                            Ok
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}