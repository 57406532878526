import React, { useContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { fabric } from 'fabric'
import { useEffect, useRef, useState, useCallback } from "react";
import { Card, Alert, Form, Row, Col, Button, Modal } from "react-bootstrap"
import { UserContext } from "../context/UserContext";
import { useParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form"
import Gondola from "../components/Gondola";
import { FetchAndCreateBackgroundStoreImage, InitCanvas, FILL_FILTERED_COLOR, FILL_UNFILTERED_COLOR, STROKE_FILTERED_COLOR, STROKE_UNFILTERED_COLOR, DEFAULT_CANVAS_RESOLUTION_X, DEFAULT_CANVAS_RESOLUTION_Y } from "../helpers/FabricJSHelpers";


export default function Gondolas(props) {
    const canvas = useRef(null)
    const inputFileRef = useRef(null)
    const [activeFilterEntity, setActiveFilterEntity] = useState("gondola")
    const [totalScalesNum, setTotalScalesNum] = useState(0)
    const [totalScalesInPublicBackendNum, setTotalScalesInPublicBackendNum] = useState(null)
    const [showInconsistentScalesAlert, setShowInconsistentScalesAlert] = useState(false)
    const [totalGatewaysNum, setTotalGatewaysNum] = useState(0)
    const [totalGatewaysInPublicBackendNum, setTotalGatewaysInPublicBackendNum] = useState(null)
    const [showInconsistentGatewaysAlert, setShowInconsistentGatewaysAlert] = useState(false)
    const [totalGondolasNum, setTotalGondolasNum] = useState(0)
    const [totalGondolasInPublicBackendNum, setTotalGondolasInPublicBackendNum] = useState(null)
    const [showInconsistentGondolasAlert, setShowInconsistentGondolasAlert] = useState(false)
    const [totalFilteredNum, setTotalFilteredNum] = useState(0)
    const [selectedGondola, setSelectedGondola] = useState(null)
    const [showIPScanModal, setShowIPScanModal] = useState(false)
    const [showGondolasFilterModal, setShowGondolasFilterModal] = useState(false)
    const [showScalesFilterModal, setShowScalesFilterModal] = useState(false)
    const [showGatewaysFilterModal, setShowGatewaysFilterModal] = useState(false)
    const [ipScanError, setIPScanError] = useState(false)
    const [ipScanErrorMessage, setIPScanErrorMessage] = useState("")
    const [gatewaysConnectedNum, setGatewaysConnectedNum] = useState(0)
    const [gatewaysDisconnectedNum, setGatewaysDisconnectedNum] = useState(0)
    const [gatewaysUpdatedNum, setGatewaysUpdatedNum] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const { token, userenv, setToken } = useContext(UserContext)
    const { register, handleSubmit, reset } = useForm()

    const { storeId } = useParams()

    const location = useLocation()

    const navigate = useNavigate()
    useEffect(() => {
        if (!storeId.match(/^(\d+,)*(\d+)$/)) {
            navigate("/404")
        }
    }, [storeId, navigate])



    const updateGondolaColor = (gondola) => {
        let canvasObjs = canvas.current.getObjects()

        for (let i = 0; i < canvasObjs.length; i++) {
            if ("gondola_id" in canvasObjs[i] && canvasObjs[i].gondola_id === gondola.gondola_id) {
                if (gondola.filtered) {
                    canvasObjs[i]._objects[0].set("fill", FILL_FILTERED_COLOR)
                    canvasObjs[i]._objects[0].set("stroke", STROKE_FILTERED_COLOR)
                    canvasObjs[i]._objects[1].set("fill", "black")
                } else {
                    canvasObjs[i].set("fill", FILL_UNFILTERED_COLOR)
                }
            }
        }
    }

    const clearGondolas = () => {
        let canvasObjs = canvas.current.getObjects()
        for (let i = 0; i < canvasObjs.length; i++) {
            if ("gondola_id" in canvasObjs[i]) {
                canvas.current.remove(canvasObjs[i])
            }
        }
    }

    const fetchAndDrawGondolas = useCallback(() => {
        var URL = `${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/gondolas`
        fetch(URL,
            {
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                })
            })
            .then(response => {
                if (response.status === 401) {
                    setToken(null);
                } else return response.json();
            })
            .then(data => { 
                console.log(data)
                setTotalScalesNum(data.total_scales);
                setTotalGatewaysNum(data.total_gateways);
                setTotalGondolasNum(data.gondolas.length);
                setTotalScalesInPublicBackendNum(data.total_scales_in_backend);
                setTotalGatewaysInPublicBackendNum(data.total_gateways_in_backend);
                setTotalGondolasInPublicBackendNum(data.total_gondolas_in_backend);
                
                if(data.total_scales_in_backend !== null && data.total_scales !== data.total_scales_in_backend){                   
                    setShowInconsistentScalesAlert(true);
                }else{
                    setShowInconsistentScalesAlert(false);
                }
                
                if(data.total_gateways_in_backend !== null && data.total_gateways !== data.total_gateways_in_backend){
                    setShowInconsistentGatewaysAlert(true);
                }else{
                    setShowInconsistentGatewaysAlert(false);
                }
                
                if(data.total_gondolas_in_backend !== null && data.gondolas.length !== data.total_gondolas_in_backend){
                    setShowInconsistentGondolasAlert(true);
                }else{
                    setShowInconsistentGondolasAlert(false);
                }


                data.gondolas.forEach(addGondolaRectangle) })
            .catch(err => console.log(err))
    }, [storeId, token, setToken, userenv]);

    const fetchAndUpdateGondolasColor = useCallback(() => {
        var URL = `${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/gondolas`
        if (Array.from(searchParams).length > 0) {
            URL = URL + "?" + searchParams.toString()
        }
        fetch(URL, {
            headers: new Headers({
                "Authorization": "Bearer " + token,
            })
        })
            .then(response => {
                if (response.status === 401) {
                    setToken(null);
                } else return response.json();
            })
            .then(data => {
                setTotalFilteredNum(data.total_filtered);
                data.gondolas.forEach(updateGondolaColor);
                canvas.current.renderAll()
            })
            .catch(err => console.log(err))
    }, [searchParams, token, setToken, storeId, userenv]);

    useEffect(() => {
        props.setSelectedTab("gondolas")
    })
    useEffect(() => {
        setSelectedGondola(null)
    }, [location])

    //Set Canvas with Store Image
    useEffect(() => {
        canvas.current = InitCanvas(DEFAULT_CANVAS_RESOLUTION_X, DEFAULT_CANVAS_RESOLUTION_Y)
        console.log("Canvas loaded")
        FetchAndCreateBackgroundStoreImage(canvas.current, storeId, props.imgType, token, userenv)
        fetchAndDrawGondolas()

        canvas.current.on("selection:updated", (e) => {
            setSelectedGondola(e.selected[0])
        })

        canvas.current.on("selection:created", (e) => {
            setSelectedGondola(e.selected[0])
        })

        canvas.current.on("selection:cleared", (e) => {
            setSelectedGondola(null)
        })
        // destroy fabric on unmount
        return () => {
            canvas.current.dispose();
            canvas.current = null;
        };

    }, [storeId, props.imgType, token, setToken, userenv, fetchAndDrawGondolas])

    useEffect(() => {
        const interval = setInterval(fetchAndUpdateGondolasColor, 5000);
        return () => clearInterval(interval);
    }, [storeId, fetchAndUpdateGondolasColor, searchParams, token]);
    useEffect(fetchAndUpdateGondolasColor, [storeId, fetchAndUpdateGondolasColor, token, searchParams])

    const addGondolaRectangle = (gondola) => {
        let gondola_width = gondola.dimensions_x
        let gondola_height = gondola.dimensions_y
        let gondola_area = gondola_width * gondola_height
        let gondolaFontSize = 10
        if (gondola_area > 2000) gondolaFontSize = 20

        let gondola_name = gondola.gondola_external_id ? gondola.gondola_external_id : ""
        let g = new fabric.Rect({
            originX: "center",
            originY: "center",
            width: gondola_width,
            height: gondola_height,
            fill: FILL_UNFILTERED_COLOR,
            stroke: STROKE_UNFILTERED_COLOR,
            strokeWidth: 3
        })

        let text = new fabric.Text(gondola_name, {
            fontSize: gondolaFontSize,
            fontFamily: "Arial",
            fill: "white",
            angle: -gondola.yaw,
            originX: "center",
            originY: "center",
        })

        let group = new fabric.Group([g, text], {
            gondola_id: gondola.gondola_id,
            gondola_external_id: gondola.gondola_external_id,
            filtered: gondola.filtered,
            originX: "center",
            originY: "center",
            hasControls: false,
            lockMovementX: true,
            lockMovementY: true,
            left: Math.round(gondola.img_pos_x),
            top: Math.round(gondola.img_pos_y),
            angle: gondola.yaw,
            borderColor: "orange",
            hoverCursor: "crosshair",
            borderScaleFactor: 3
        })

        canvas.current.add(group)
    }

    const onSubmitFilter = (data) => {
        let newSearchParams = {}
        for (const [key, value] of Object.entries(data)) {
            if (value !== null && value !== undefined && value !== "" && value !== "-") {
                newSearchParams[key] = value
            }
            setSearchParams(newSearchParams)
        }
    }

    const onClickResetFilter = () => {
        setSearchParams(new URLSearchParams())
        let canvasObjs = canvas.current.getObjects()
        for (let i = 0; i < canvasObjs.length; i++) {
            if ("gondola_id" in canvasObjs[i]) {
                canvasObjs[i]._objects[0].set("fill", FILL_UNFILTERED_COLOR)
                canvasObjs[i]._objects[0].set("stroke", STROKE_UNFILTERED_COLOR)
                canvasObjs[i]._objects[1].set("fill", "white")
            }
        }
        canvas.current.renderAll()
    }


    const handleFileSelected = (event) => {
        var fileData = new FormData()
        fileData.append("ip_scan_file", inputFileRef.current?.files[0])
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${storeId}/gateways/ip-scan-file`,
            {
                method: 'POST',
                body: fileData,
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                })
            }).then(response => {
                if (response.status === 401) setToken(null)
                if (!response.ok) {
                    setIPScanError(true)
                }
                return response.json()
            })
            .then(data => {
                if (!ipScanError) {
                    setGatewaysConnectedNum(data.total_connected)
                    setGatewaysDisconnectedNum(data.total_disconnected)
                    setGatewaysUpdatedNum(data.total_updated)
                } else {
                    setIPScanErrorMessage(data.detail)
                }
                setShowIPScanModal(true)
            })
            .catch(err => console.log(err))

        // Reset file input value so that the same file can be selected
        event.target.value = null
    }

    return (
        <div className="App">
            <header className="App-header">
                <h2 className="mt-5">Gondolas</h2>
            </header>
            <div className="App-filter-options ">
                <Row className="align-items-center">
                    <Col xs="auto">
                        <Button variant="primary" size="sm" onClick={() => { reset(); setActiveFilterEntity("gondola"); setShowGondolasFilterModal(true); }}>
                            Filter Gondolas
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button variant="primary" size="sm" onClick={() => { reset(); setActiveFilterEntity("gateways"); setShowGatewaysFilterModal(true); }}>
                            Filter Gateways
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button variant="primary" size="sm" onClick={() => { reset(); setActiveFilterEntity("scale"); setShowScalesFilterModal(true); }}>
                            Filter Scales
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button onClick={onClickResetFilter} size="sm" variant="warning">
                            Reset
                        </Button>
                    </Col>
                    <Col xs="auto">
                        {Array.from(searchParams).length > 0 &&
                            <Form.Label className="mt-2">Filters: ('{searchParams.toString().replace('&', ', ')}') &nbsp;</Form.Label>
                        }

                        {Array.from(searchParams).length === 0 && activeFilterEntity === "gateways" &&
                            <Form.Label className="mt-2">{totalGatewaysNum} gateways</Form.Label>}

                        {Array.from(searchParams).length === 0 && activeFilterEntity === "gondola" &&
                            <Form.Label className="mt-2">{totalGondolasNum} gondolas</Form.Label>}

                        {Array.from(searchParams).length === 0 && activeFilterEntity === "scale" &&
                            <Form.Label className="mt-2">{totalScalesNum} scales</Form.Label>}

                        {Array.from(searchParams).length > 0 && activeFilterEntity === "gondola" &&
                            <Form.Label className="mt-2">{totalFilteredNum}/{totalGondolasNum} gondolas</Form.Label>}

                        {Array.from(searchParams).length > 0 && activeFilterEntity === "gateways" &&
                            <Form.Label className="mt-2">{totalFilteredNum}/{totalGatewaysNum} gateways</Form.Label>}

                        {Array.from(searchParams).length > 0 && activeFilterEntity === "scale" &&
                            <Form.Label className="mt-2">{totalFilteredNum}/{totalScalesNum} scales</Form.Label>}
                    </Col>
                    <Col xs="auto">
                        <input className="d-none" onChange={handleFileSelected} ref={inputFileRef} type="file" />
                        <Button onClick={() => inputFileRef.current?.click()} size="sm" variant="secondary">
                            Upload IP Scan
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="App-body">
                <div className="canvas-container">
                    <canvas id="canvas" />
                </div>

                <div className="CardList">
                    {showInconsistentGondolasAlert &&
                        <Alert className="App-labels" variant="danger" onClose={() => setShowInconsistentGondolasAlert(false)} dismissible>
                            Inconsistent Gondolas: {totalGondolasNum} in deployment and {totalGondolasInPublicBackendNum} in database.
                        </Alert>}

                    {showInconsistentScalesAlert &&
                        <Alert className="App-labels" variant="danger" onClose={() => setShowInconsistentScalesAlert(false)} dismissible>
                            Inconsistent Scales: {totalScalesNum} in deployment and {totalScalesInPublicBackendNum} in database.
                        </Alert>}
                    
                    {showInconsistentGatewaysAlert &&
                        <Alert className="App-labels" variant="danger" onClose={() => setShowInconsistentGatewaysAlert(false)} dismissible>
                            Inconsistent Gateways: {totalGatewaysNum} in deployment and {totalGatewaysInPublicBackendNum} in database.
                        </Alert>}

                    {selectedGondola &&
                        <Gondola key={selectedGondola.gondola_id} storeId={storeId}
                            gondola_id={selectedGondola.gondola_id}
                            clearGondolas={clearGondolas}
                            fetchAndDrawGondolas={fetchAndDrawGondolas} />}

                    {!selectedGondola &&
                        <Card className="App-labels mb-5">
                            <Card.Body className="Card-body">
                                <Card.Title className="mb-4">Gondola Details</Card.Title>
                                <Alert variant="primary">
                                    No gondola selected.
                                </Alert>
                            </Card.Body>
                        </Card>
                    }

                </div>
            </div>
            <Modal backdrop="static" show={showIPScanModal} onHide={() => setShowIPScanModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>IP Scan Result</Modal.Title>
                </Modal.Header>
                {ipScanError ?
                    <Modal.Body>
                        <Alert className="mt-3" variant="danger" >
                            An error occurred when parsing the IPs file!
                        </Alert>
                        <p>Error: {ipScanErrorMessage}</p>
                    </Modal.Body>
                    :
                    <Modal.Body>
                        <Alert className="mt-3" variant="success" >
                            The file was parsed successfully!
                        </Alert>
                        <p>Number of gateways updated: {gatewaysUpdatedNum}</p>
                        <p>Number of gateways connected: {gatewaysConnectedNum}</p>
                        <p>Number of gateways disconnected: {gatewaysDisconnectedNum}</p>
                    </Modal.Body>
                }
                <Modal.Footer>
                    <Button onClick={() => setShowIPScanModal(false)} variant="primary">
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop="static" show={showGatewaysFilterModal} onHide={() => setShowGatewaysFilterModal(false)}>
                <Form noValidate onSubmit={handleSubmit(onSubmitFilter)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter Gateways</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mt-3" controlId="form.BackendId">
                            <Form.Label>Backend Id</Form.Label>
                            <Form.Control inline size="sm" type="text" placeholder="<backend_id>"
                                {...register("gateway_backend_id")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.MacAddress">
                            <Form.Label>Mac Address</Form.Label>
                            <Form.Control inline size="sm" type="text" placeholder="<mac_address>"
                                {...register("gateway_mac_address")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.IpAddress">
                            <Form.Label>IP Address</Form.Label>
                            <Form.Control inline size="sm" type="text" placeholder="<ip_address>"
                                {...register("gateway_ip_address")} />
                        </Form.Group>
                        <Form.Group className="mt-4" controlId="form.ConnectedCheck">
                            <Form.Label >Connected: &nbsp; </Form.Label>
                            <Form.Check inline label="Any" value="-" name="gateway_connected" type="radio" defaultChecked {...register("gateway_connected")} />
                            <Form.Check inline label="True" value={true} name="gateway_connected" type="radio" {...register("gateway_connected")} />
                            <Form.Check inline label="False" value={false} name="gateway_connected" type="radio" {...register("gateway_connected")} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShowGatewaysFilterModal(false)} type="submit" variant="primary">
                            Ok
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


            <Modal backdrop="static" show={showGondolasFilterModal} onHide={() => setShowGondolasFilterModal(false)}>
                <Form noValidate onSubmit={handleSubmit(onSubmitFilter)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter Gondolas</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mt-3" controlId="form.ExternalId">
                            <Form.Label>External Id</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="<external_id>"
                                {...register("gondola_external_id")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.BackendId">
                            <Form.Label>Backend Id</Form.Label>
                            <Form.Control inline size="sm" type="text" placeholder="<backend_id>"
                                {...register("gondola_backend_id")} />
                        </Form.Group>

                        <Form.Group className="mt-4" controlId="form.Calibrated">
                            <Form.Label >Calibrated: &nbsp; </Form.Label>
                            <Form.Check inline label="Any" value="-" name="calibrated" type="radio" defaultChecked {...register("calibrated")} />
                            <Form.Check inline label="True" value={true} name="calibrated" type="radio" {...register("calibrated")} />
                            <Form.Check inline label="False" value={false} name="calibrated" type="radio" {...register("calibrated")} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShowGondolasFilterModal(false)} type="submit" variant="primary">
                            Ok
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal backdrop="static" show={showScalesFilterModal} onHide={() => setShowScalesFilterModal(false)}>
                <Form noValidate onSubmit={handleSubmit(onSubmitFilter)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter Scales</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mt-3" controlId="form.SerialNumber">
                            <Form.Label>Serial Number</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="<serial_number>"
                                {...register("scale_serial_number")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.BackendId">
                            <Form.Label>Backend Id</Form.Label>
                            <Form.Control inline size="sm" type="text" placeholder="<backend_id>"
                                {...register("scale_backend_id")} />
                        </Form.Group>
                        <Form.Group className="mt-4" controlId="form.QACheck">
                            <Form.Label >QA Check: &nbsp; </Form.Label>
                            <Form.Check inline label="Any" value="-" name="scale_qa_check" type="radio" defaultChecked {...register("scale_qa_check")} />
                            <Form.Check inline label="True" value={true} name="scale_qa_check" type="radio" {...register("scale_qa_check")} />
                            <Form.Check inline label="False" value={false} name="scale_qa_check" type="radio" {...register("scale_qa_check")} />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="form.QAState">
                            <Form.Label >QA State: &nbsp; </Form.Label>
                            <Form.Check inline label="Any" value="-" name="scale_qa_state" type="radio" defaultChecked {...register("scale_qa_state")} />
                            <Form.Check inline label="True" value={true} name="scale_qa_state" type="radio" {...register("scale_qa_state")} />
                            <Form.Check inline label="False" value={false} name="scale_qa_state" type="radio" {...register("scale_qa_state")} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShowScalesFilterModal(false)} type="submit" variant="primary">
                            Ok
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}