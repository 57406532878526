
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { Alert, Card, ListGroup, ListGroupItem, Button, Modal, Form, ModalDialog, FormSelect } from "react-bootstrap"
import { UserContext } from "../context/UserContext"
import { useForm } from "react-hook-form"
import { FaTrash } from "react-icons/fa"
import { FaEdit } from "react-icons/fa"
import { TbReplaceFilled } from "react-icons/tb";
import React, { useContext } from "react"

import Draggable from "react-draggable";

const GENERIC_ERROR_MESSAGE = "Oops... Something went wrong!";

class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".modal-header"><ModalDialog {...this.props} />
        </Draggable>
    }
}

export default function Scale(props) {
    const { token, userenv, setToken } = useContext(UserContext)
    const [showEditScaleModal, setShowEditScaleModal] = useState(false)
    const [showRemoveScaleModal, setShowRemoveScaleModal] = useState(false)
    const [showReplaceScaleModal, setShowReplaceScaleModal] = useState(false)
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [scanErrorMessage, setScanErrorMessage] = useState("")
    const [replaceScaleErrorMessage, setReplaceScaleErrorMessage] = useState("")
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const { register: registerFormReplaceScale, handleSubmit: handleSubmitFormReplaceScale, reset: resetFormReplaceScale } = useForm()

    const handleClose = () => { setShowErrorAlert(false); reset(); setShowEditScaleModal(false); };
    const handleShow = () => { reset(); setShowEditScaleModal(true) };
    const handleCloseReplaceScaleModal = () => { setShowErrorAlert(false); resetFormReplaceScale(); setShowReplaceScaleModal(false); };

    const onSubmit = (data) => {
        var isResponseOK = false
        let newScaleInfo = {}

        newScaleInfo.scale_id = props.scale.scale_id
        newScaleInfo.scale_gateway_id = Number(data.new_gateway_id)
        newScaleInfo.shelf_id = data.shelf_id
        newScaleInfo.backend_id = props.scale.backend_id
        newScaleInfo.vertical_position = props.scale.vertical_position
        newScaleInfo.serial_number = data.serial_number
        newScaleInfo.qa_check = data.qa_check
        newScaleInfo.qa_state = data.qa_state
        newScaleInfo.qa_comment = data.qa_comment

        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/gondolas/${props.gondola_id}/` +
            `gateways/${props.scale.scale_gateway_id}/scales`,
            {
                method: 'PATCH',
                body: JSON.stringify(newScaleInfo),
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-type": "application/json"
                })
            })
            .then(response => {
                if (response.status === 401) setToken(null)
                if (response.ok) {
                    isResponseOK = true
                } else {
                    setShowErrorAlert(true)
                }
                return response.json()
            }).then(response_json => {
                if (isResponseOK) {
                    props.fetchGondolaInfo()
                    handleClose();
                } else {
                    if (Array.isArray(response_json.detail)) {
                        setScanErrorMessage(response_json.detail[0].msg)
                    } else {
                        setScanErrorMessage(response_json.detail)
                    }
                }
            }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    const onSubmitReplaceScale = (data) => {
        var isResponseOK = false
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/gondolas/${props.gondola_id}/` +
            `gateways/${props.scale.scale_gateway_id}/scales/${props.scale.scale_id}/replace`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-type": "application/json"
                })
            })
            .then(response => {
                if (response.status === 401) setToken(null)
                if (response.ok) {
                    props.fetchGondolaInfo()
                    handleCloseReplaceScaleModal()
                } else {
                    setShowErrorAlert(true)
                }
                return response.json()
            }).then(response_json => {
                if (!isResponseOK) {
                    if (Array.isArray(response_json.detail)) {
                        setReplaceScaleErrorMessage(response_json.detail[0].msg)
                    } else {
                        setReplaceScaleErrorMessage(response_json.detail)
                    }
                }
            }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    const onClickRemoveScale = (e) => {
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}` +
            `/gondolas/${props.gondola_id}/scales/${props.scale.scale_id}`, {
            method: 'DELETE',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        }).then(response => {
            setShowRemoveScaleModal(false)
            if (response.ok) {
                props.fetchGondolaInfo()
            }
            else {
                setShowErrorAlert(true)
                return response.json()
            }
        }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    const determineScaleGatewayBackendId = (scale_gateway_id) => {
        let gateway_backend_id = props.gateways.filter(gateway => gateway.scale_gateway_id === scale_gateway_id)[0].backend_id
        if (gateway_backend_id === null) {
            return scale_gateway_id
        }else{
            return gateway_backend_id
        }
    }

    return (
        <div>
            <Card className="App-labels mb-5 ">
                <Card.Body className="Card-body">
                    <Card.Title className="mb-1">Scale &nbsp;
                        {props.scale.serial_number === null ?
                            props.scale.vertical_position
                            :
                            props.scale.serial_number
                        }
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="primary" size="sm" onClick={handleShow}>
                            <FaEdit />
                        </Button>
                        &nbsp;
                        <Button variant="warning" onClick={() => setShowReplaceScaleModal(true)} size="sm">
                            <TbReplaceFilled />
                        </Button>
                        &nbsp;
                        <Button variant="danger" size="sm" onClick={() => setShowRemoveScaleModal(true)}>
                            <FaTrash />
                        </Button>
                    </Card.Title>
                    {props.scale.serial_number !== null &&
                        <Form>
                            <ListGroup className="mt-3 mb-3">

                                <ListGroupItem>
                                    Vertical Position: &nbsp; {props.scale.vertical_position}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Serial Number: &nbsp; {props.scale.serial_number}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Shelf Id: &nbsp; {props.scale.shelf_id}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Backend Id: &nbsp; {props.scale.backend_id}
                                </ListGroupItem>
                                <ListGroupItem>
                                    QA Check: &nbsp; &nbsp;
                                    <Form.Check inline disabled checked={props.scale.qa_check} type="checkbox" id="qa-check-switch" />
                                </ListGroupItem>
                                <ListGroupItem>
                                    QA State: &nbsp; &nbsp;
                                    <Form.Check inline disabled checked={props.scale.qa_state} type="checkbox" id="qa-state-switch" />
                                </ListGroupItem>
                                {props.scale.qa_comment !== null && props.scale.qa_comment !== "" &&
                                    <ListGroupItem>
                                        QA Comment: &nbsp; {props.scale.qa_comment}
                                    </ListGroupItem>}
                                <ListGroupItem>
                                    Gateway Id: &nbsp; {determineScaleGatewayBackendId(props.scale.scale_gateway_id)}
                                </ListGroupItem>

                            </ListGroup>
                        </Form>
                    }
                </Card.Body>
            </Card>
            <Modal dialogAs={DraggableModalDialog} backdrop="static" show={showEditScaleModal} onHide={handleClose}>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Scale Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="form.EditScale">
                            {setValue("serial_number", props.scale.serial_number)}
                            {setValue("qa_check", props.scale.qa_check)}
                            {setValue("qa_state", props.scale.qa_state)}
                            {setValue("qa_comment", props.scale.qa_comment)}
                            {setValue("shelf_id", props.scale.shelf_id)}
                            Gateway:
                            <FormSelect className="mt-1 mb-3" defaultValue={props.scale.scale_gateway_id}
                                    {...register("new_gateway_id")}>

                                {props.gateways.map((gateway, index) => (
                                    <option key={index} value={gateway.scale_gateway_id}>
                                        {gateway.mac_address}
                                    </option>
                                ))}
                            </FormSelect>
                            <Form.Label>Serial Number:</Form.Label>
                            <Form.Control type="text" placeholder="<serial number>" autoFocus
                                {...register("serial_number", { required: true, valueAsNumber: true, validate: (v) => v > 0 })}
                                isInvalid={errors.serial_number} />
                            <Form.Control.Feedback type="invalid">
                                Please fill in a valid scan value.
                            </Form.Control.Feedback>
                            <Form.Label className="mt-3">QA Check:</Form.Label> &nbsp;&nbsp;&nbsp;
                            <Form.Check inline {...register("qa_check")} type="checkbox" id="qa-check-switch" /><br />
                            <Form.Label className="mt-3">QA State:</Form.Label> &nbsp;&nbsp;&nbsp;
                            <Form.Check inline {...register("qa_state")} type="checkbox" id="qa-state-switch" /><br />
                            <Form.Label className="mt-3">QA Comment:</Form.Label> &nbsp;&nbsp;&nbsp;
                            <Form.Control as="textarea" {...register("qa_comment")} rows={3} />
                        </Form.Group>
                        <Form.Group>
                        </Form.Group>
                        {showErrorAlert &&
                            <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {scanErrorMessage}
                            </Alert>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal backdrop="static" show={showRemoveScaleModal} onHide={() => setShowRemoveScaleModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Scale</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove this scale ?
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" onClick={onClickRemoveScale} variant="primary">
                        Yes
                    </Button>
                    <Button type="submit" onClick={() => setShowRemoveScaleModal(false)} variant="secondary">
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal dialogAs={DraggableModalDialog} backdrop="static" show={showReplaceScaleModal} onHide={handleCloseReplaceScaleModal}>
                <Form noValidate onSubmit={handleSubmitFormReplaceScale(onSubmitReplaceScale)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Replace Scale</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="form.ReplaceScale">
                            <Form.Label>Replacement Serial Number:</Form.Label>
                            <Form.Control type="number" placeholder="<serial_number>" autoFocus
                                {...registerFormReplaceScale("replacement_serial_number", { required: true, valueAsNumber: true })} />
                        </Form.Group>
                        {showErrorAlert &&
                            <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {replaceScaleErrorMessage}
                            </Alert>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}