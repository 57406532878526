import React, { useContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form"
import { useState } from "react";
import { Alert, Card, Button, Form} from "react-bootstrap"
import { UserContext } from "../context/UserContext";
import { SENSEI_BACKEND_ENVS, BuildSenseiAuthURL } from "../helpers/URLHelper";


const SENSEI_AUTH_APP_CODE = "TSD"

export default function Login(props) {
    const [errorMessage, setErrorMessage] = useState("")
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const {setRefreshToken, setToken} = useContext(UserContext)

    const { register, handleSubmit, formState: { errors } } = useForm()

    const registerUser = async (token, displayName) => {
        return fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/users?display_name=${displayName}`, {
            method: 'PATCH',
            headers: new Headers({
                "Authorization" : "Bearer " + token,
        })})
    }

    const onSubmit = async (data) => {
        let backend_env = data.environment.toLowerCase()

        if (!SENSEI_BACKEND_ENVS.includes(backend_env)) {
            setErrorMessage("Invalid environment")
            setShowErrorAlert(true)
            return;
        }

        data.appCode = SENSEI_AUTH_APP_CODE
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                "Content-type": "application/json",
            }),
            body: JSON.stringify(data)
        }
        const response = await fetch(`${BuildSenseiAuthURL(backend_env)}/login`, requestOptions)
        const response_data = await response.json()
        if(response.ok) {
            localStorage.setItem("sensei-user-token", response_data.accessToken)
            localStorage.setItem("sensei-refresh-token", response_data.refreshToken)
            localStorage.setItem("sensei-user-name", response_data.user.loginName)
            localStorage.setItem("sensei-user-id", response_data.user.id)
            localStorage.setItem("sensei-user-env", backend_env)
            let registerUserResponse = await registerUser(response_data.accessToken, response_data.user.name)
            console.log("RegisterResponse: "+registerUserResponse.status)
            setToken(response_data.accessToken)
            setRefreshToken(response_data.refreshToken)

        }else{
            setErrorMessage(response_data.message)
            setShowErrorAlert(true)
        }
    }
    
    return (
        <div className="App-login-body mt-5">
            <Card className="App-labels">
                <Card.Body className="Login-card-body">
                    <Card.Title className="mb-4">Login</Card.Title>
                    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group controlId="form.Username">
                            <Form.Label>Username:</Form.Label>
                            <Form.Control type="text" placeholder="Enter username" autoFocus  {...register("username", { required: true })} isInvalid={errors.username} />
                            <Form.Control.Feedback type="invalid">
                                Please fill in a valid value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group  className="mt-4 mb-4" controlId="form.Password">
                            <Form.Label>Password:</Form.Label>
                            <Form.Control type="password"  placeholder="Password"  autoFocus {...register("password", { required: true })} isInvalid={errors.password} />
                            <Form.Control.Feedback type="invalid">
                                Please fill in a valid value.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group  className="mt-4 mb-4" controlId="form.Environment">
                            <Form.Label>Env:</Form.Label>
                            <Form.Control type="text"  placeholder="Environment"  autoFocus {...register("environment", { required: true })} isInvalid={errors.environment} />
                            <Form.Control.Feedback type="invalid">
                                Please fill in a valid value.
                            </Form.Control.Feedback>
                        </Form.Group> 
                        {showErrorAlert && 
                            <Alert className="mb-4" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {errorMessage}
                            </Alert>}
                        <Button type="submit" variant="primary">
                            Login
                        </Button>
                </Form>
                </Card.Body>
            </Card>
        </div>
    )
}