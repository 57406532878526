import React, { useContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { Card, ListGroup, ListGroupItem, Button, Modal, Form } from "react-bootstrap"
import {UserContext} from "../context/UserContext"
import { FaTrash } from "react-icons/fa"

const GENERIC_ERROR_MESSAGE = "Oops... Something went wrong!";

export default function CubAruco(props) {
    const [cuboArucoSampledCheck, setCuboArucoSampledCheck] = useState(props.selectedCubAruco.sampled)
    const [cuboArucoDoubleSampledCheck, setCuboArucoDoubleSampledCheck] = useState(props.selectedCubAruco.double_sampled)
    const [showRemoveCuboArucoPlacementModal, setShowRemoveCuboArucoPlacementModal] = useState(null)
    const {token, userenv, setToken} = useContext(UserContext)

    const handleSampledCheckChange = (e) => {
        setCuboArucoSampledCheck(e.target.checked)
        props.changeSelectedCubArucoSampledValue(e.target.checked, 1)
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/cubarucos/${props.selectedCubAruco.cubaruco_id}/sampled?sampled_value=${e.target.checked}`, {
            method: 'PATCH',
            headers: new Headers({
                "Authorization" : "Bearer " + token,
            })})
        .then(response => {
            if(response.status === 401){
                setToken(null); 
            };
            if (!response.ok) {
                setCuboArucoSampledCheck(!e.target.checked)
                props.changeSelectedCubArucoSampledValue(!e.target.checked, 1)
                alert(GENERIC_ERROR_MESSAGE)
            }
        }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    const handleDoubleSampledCheckChange = (e) => {
        setCuboArucoDoubleSampledCheck(e.target.checked)
        props.changeSelectedCubArucoSampledValue(e.target.checked, 2)
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/cubarucos/${props.selectedCubAruco.cubaruco_id}/double-sampled?sampled_value=${e.target.checked}`, {
            method: 'PATCH',
            headers: new Headers({
                "Authorization" : "Bearer " + token,
            })})
        .then(response => {
            if(response.status === 401){
                setToken(null); 
            };
            if (!response.ok) {
                setCuboArucoDoubleSampledCheck(!e.target.checked)
                props.changeSelectedCubArucoSampledValue(!e.target.checked, 2)
                alert(GENERIC_ERROR_MESSAGE)
            }
        }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }


    const onClickRemovePlacement = () => {
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/cubarucos/${props.selectedCubAruco.cubaruco_id}`,
        {
            method: 'DELETE',
            headers: new Headers({
                "Authorization" : "Bearer " + token,
        })}).then(response => {
            if(response.status === 401){
                setToken(null); 
            };
            setShowRemoveCuboArucoPlacementModal(false);
            props.removeSelectedCubAruco()
        })
        .catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    return (
        <div>
            <Card className="App-labels mb-5 ">
                <Card.Body className="Card-body">
                    <Card.Title className="mb-3">Placement {props.selectedCubAruco.cubaruco_id} &nbsp; &nbsp;
                        <Button variant="danger" onClick={() => setShowRemoveCuboArucoPlacementModal(true)} size="sm">
                            <FaTrash />
                        </Button></Card.Title>
                        <Form>
                            <ListGroup className="mt-3 mb-3">
                                <ListGroupItem>
                                    Type: &nbsp; {props.selectedCubAruco.location_type} 
                                </ListGroupItem>
                                <ListGroupItem>
                                    Cameras: &nbsp; {'[' + props.selectedCubAruco.camera_ids.map(id => id.toString()).join(', ') + ']'}
                                </ListGroupItem>
                                <ListGroupItem>
                                    1st Sample: &nbsp;
                                    <Form.Check inline checked={cuboArucoSampledCheck}  disabled={cuboArucoDoubleSampledCheck}
                                    onChange={handleSampledCheckChange} type="checkbox" id="cubaruco-sampled-check-switch" />
                                </ListGroupItem>
                                {props.selectedCubAruco.location_type === "shelf" &&
                                <ListGroupItem>
                                    2nd Sample: &nbsp;
                                    <Form.Check inline checked={cuboArucoDoubleSampledCheck}  disabled={!cuboArucoSampledCheck}
                                    onChange={handleDoubleSampledCheckChange} type="checkbox" id="cubaruco-double-sampled-check-switch" />
                                </ListGroupItem>}
                            </ListGroup>
                        </Form>
                </Card.Body>
            </Card>
            <Modal backdrop="static" show={showRemoveCuboArucoPlacementModal} onHide={() => setShowRemoveCuboArucoPlacementModal(false)}>
                   <Modal.Header closeButton>
                        <Modal.Title>Remove Placement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    Are you sure you want to remove this cubaruco placement? (This operation is irreversible)
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" onClick={onClickRemovePlacement} variant="primary">
                            Yes
                        </Button>
                        <Button type="submit" onClick={() => setShowRemoveCuboArucoPlacementModal(false)} variant="secondary">
                            No
                        </Button>
                    </Modal.Footer>
            </Modal>
        </div>
    )
}